import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControl as MuiFormControl } from "@material-ui/core";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, isValid, parseISO } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "../UI/Autocomplete/Autocomplete";
import { AutocompleteOption } from "../../Utils/Autocomplete";
import { ResetFilter } from "../Layout/FilterPanel/ResetFilter";
import {
  traineeTypeDropDown,
  isActiveOptions,
  STORAGE_TYPE,
  STORAGE_NAMES,
} from "../../constants";
/** Services */
import { getOutcomesFilters } from "../../services/outcomesService";
import { useOutcomesContext } from "../../services/outcomesContext";
import { useOutcomesSelectedContext } from "../../services/outcomesSelectedContext";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { useBrowserStorage } from "../../services/StorageService/StorageHelper";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import { useOutcomesFilterStyles } from "./OutcomesFilter.styles";
import { useCommonStyles } from "../shared/common.styles";

const initialOutcomesFilterValues = {
  traineeName: "",
  facultyName: "",
  traineeType: "",
  currentTrainee: "",
  trainingStartDateFrom: "",
  trainingStartDateTo: "",
  trainingEndDateFrom: "",
  trainingEndDateTo: "",
  isActive: isActiveOptions[0],
};

const OutcomesFilterDef = ({ changeFilterData, submissionUpdate }) => {
  const { t } = useTranslation();
  const classes = useOutcomesFilterStyles();
  const commonClasses = useCommonStyles();
  const { saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);

  const { outcomesPreferences, setOutcomesPreferences } = useOutcomesContext();
  const { resetAllSelections } = useOutcomesSelectedContext();
  const initialFilterValues = outcomesPreferences.values.filters;

  const [traineeType, setTraineeType] = useState([]);

  const [filter, setFilter] = useState(new Map());

  const [selectedStartFromDate, setSelectedStartFromDate] = useState(
    initialFilterValues.trainingStartDateFrom
      ? parseISO(initialFilterValues.trainingStartDateFrom)
      : null
  );
  const [selectedStartToDate, setSelectedStartToDate] = useState(
    initialFilterValues.trainingStartDateTo
      ? parseISO(initialFilterValues.trainingStartDateTo)
      : null
  );

  const [selectedEndFromDate, setSelectedEndFromDate] = useState(
    initialFilterValues.trainingEndDateFrom
      ? parseISO(initialFilterValues.trainingEndDateFrom)
      : null
  );
  const [selectedEndToDate, setSelectedEndToDate] = useState(
    initialFilterValues.trainingEndDateTo
      ? parseISO(initialFilterValues.trainingEndDateTo)
      : null
  );

  const [maxDateStart, setMaxDateStart] = useState(null);
  const [maxDateEnd, setMaxDateEnd] = useState(null);

  const [minDateStart, setMinDateStart] = useState(null);
  const [minDateEnd, setMinDateEnd] = useState(null);

  const [getIsValidStartToDate, setIsValidStartToDate] = useState(false);
  const [getIsValidStartFromDate, setIsValidStartFromDate] = useState(false);
  const [getIsValidEndFromDate, setIsValidEndFromDate] = useState(false);
  const [getIsValidEndToDate, setIsValidEndToDate] = useState(false);

  const { setAlert, clearAlert } = useAlertContext();

  const [outcomesFilterSelectedValues, setOutcomesFilterSelectedValues] =
    useState({ ...initialFilterValues });

  const resetFilterValues = () => {
    saveItem(
      STORAGE_NAMES.TGDS_OUTCOME_DASHBOARD_FILTERS,
      initialOutcomesFilterValues
    );
    setOutcomesFilterSelectedValues({ ...initialOutcomesFilterValues });
    setSelectedEndToDate(null);
    setSelectedEndFromDate(null);
    setSelectedStartFromDate(null);
    setSelectedStartToDate(null);

    setFilterPreference({ ...initialOutcomesFilterValues });
    resetAllSelections();
  };

  const [resStartEndDate, setResStartEndDate] = useState([]);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const selectedSubmission = getSelectedSubmission();

  // ** Reset Filter Values on a component event **
  useEffect(() => {
    getOutcomesFilters(
      setResStartEndDate,
      setTraineeType,
      selectedSubmission,
      setAlert,
      clearAlert,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [selectedSubmission, outcomesPreferences.values.addCount]);
  if (Object.keys(resStartEndDate).length && !minDateEnd && !minDateStart) {
    setMinDateEnd(parseISO(resStartEndDate.trainingEndDateMin));
    setMinDateStart(parseISO(resStartEndDate.trainingStartDateMin));
    setMaxDateStart(parseISO(resStartEndDate.trainingStartDateMax));
    setMaxDateEnd(parseISO(resStartEndDate.trainingEndDateMax));
  }

  // *** Helper Function to Update Context Object, on Field Value Update ***
  const setFilterPreference = (fieldUpdate) => {
    setOutcomesPreferences({
      ...outcomesPreferences,
      values: {
        ...outcomesPreferences.values,
        filters: {
          ...outcomesPreferences.values.filters,
          ...fieldUpdate,
        },
        pagination: {
          page: 0,
          pageSize: 0,
        },
        sort: {
          order: "",
          orderBy: "",
        },
      },
    });
  };

  /* Update Filter with Training Start and End Dates,
   * to fetch the updated Search Results
   */
  const handleTraineeFilterDatesChange = (value, formField) => {
    setFilter(
      (filter) =>
        new Map(
          filter.set(
            formField,
            value ? format(new Date(value), "yyyy-MM-dd") : ""
          )
        )
    );
    resetAllSelections();
  };

  /* On Updates to the Filter Map, refresh the Search Results*/
  if (filter) {
    changeFilterData(filter);
  }

  // **** On Dropdown Value change, update search results ****
  const handleFilterValueUpdate = (fieldName, fieldValue, updateFilterData) => {
    const updatedFilterValues = {
      ...outcomesFilterSelectedValues,
      [fieldName]: fieldValue,
    };

    if (updateFilterData) {
      setFilterPreference(updatedFilterValues);
      setOutcomesFilterSelectedValues(updatedFilterValues);
    }
    resetAllSelections();
  };

  useEffect(() => {
    if (submissionUpdate) {
      resetFilterValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUpdate]);

  return (
    <>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="traineesNameAutoComplete"
          key="trainees-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={outcomesFilterSelectedValues.traineeName}
          label={t("trainee.mainView.filters.traineeName")}
          onChange={(e, value) => {
            handleFilterValueUpdate("traineeName", value || "", true);
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/trainees/autocomplete?context=outcomes`}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              classes={commonClasses}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="facultyNameAutoComplete"
          key="faculty-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={outcomesFilterSelectedValues.facultyName}
          label={t("trainee.mainView.filters.faculty")}
          onChange={(e, value) => {
            handleFilterValueUpdate("facultyName", value || "", true);
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/faculty/autocomplete?context=outcomes`}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              classes={commonClasses}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl>
        <label className={classes.fieldTextStyle}>
          {t("trainee.mainView.filters.trainingStartDate")}
        </label>
        <div className={classes.datePickerFormControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={minDateStart || new Date("01/01/1970")}
              maxDate={
                selectedStartToDate || maxDateStart || new Date("01/01/2100")
              }
              InputProps={{
                classes: {
                  root: classes.inputBaseRoot,
                  input: classes.inputBaseInput,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-start-from"
              margin="normal"
              value={selectedStartFromDate}
              onChange={(value) => {
                setSelectedStartFromDate(value);
                setIsValidStartFromDate(false);
                handleFilterValueUpdate(
                  "trainingStartDateFrom",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={(error, value) => {
                if (!error && !getIsValidStartFromDate) {
                  handleTraineeFilterDatesChange(
                    value,
                    "trainingStartDateFrom"
                  );
                  setIsValidStartFromDate(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <span className={classes.dateSeparator}>
              {t("trainee.mainView.filters.dateSeparator")}
            </span>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={
                selectedStartFromDate || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              InputProps={{
                classes: {
                  root: classes.inputBaseRoot,
                  input: classes.inputBaseInput,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-start-to"
              margin="normal"
              value={selectedStartToDate}
              onChange={(value) => {
                setSelectedStartToDate(value);
                setIsValidStartToDate(false);
                handleFilterValueUpdate(
                  "trainingStartDateTo",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={(error, value) => {
                if (!error && !getIsValidStartToDate) {
                  handleTraineeFilterDatesChange(value, "trainingStartDateTo");
                  setIsValidStartToDate(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </MuiFormControl>
      <MuiFormControl>
        <label className={classes.fieldTextStyle}>
          {t("trainee.mainView.filters.trainingEndDate")}
        </label>
        <div className={classes.datePickerFormControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={minDateStart || new Date("01/01/1970")}
              maxDate={
                selectedEndToDate || maxDateStart || new Date("01/01/2100")
              }
              InputProps={{
                classes: {
                  root: classes.inputBaseRoot,
                  input: classes.inputBaseInput,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              margin="normal"
              id="date-picker-inline-end-from"
              value={selectedEndFromDate}
              onChange={(value) => {
                setSelectedEndFromDate(value);
                setIsValidEndFromDate(false);
                handleFilterValueUpdate(
                  "trainingEndDateFrom",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={(error, value) => {
                if (!error && !getIsValidEndFromDate) {
                  handleTraineeFilterDatesChange(value, "trainingEndDateFrom");
                  setIsValidEndFromDate(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <span className={classes.dateSeparator}>
              {t("trainee.mainView.filters.dateSeparator")}
            </span>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              margin="normal"
              minDate={
                selectedEndFromDate || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              InputProps={{
                classes: {
                  root: classes.inputBaseRoot,
                  input: classes.inputBaseInput,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-end-to"
              value={selectedEndToDate}
              onChange={(value) => {
                setSelectedEndToDate(value);
                setIsValidEndToDate(false);
                handleFilterValueUpdate(
                  "trainingEndDateTo",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={(error, value) => {
                if (!error && !getIsValidEndToDate) {
                  handleTraineeFilterDatesChange(value, "trainingEndDateTo");
                  setIsValidEndToDate(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="traineeTypeFilterSelect"
          options={traineeType || []}
          getOptionLabel={(option) => t(traineeTypeDropDown[option])}
          value={
            outcomesFilterSelectedValues.traineeType
              ? t(outcomesFilterSelectedValues.traineeType)
              : ""
          }
          clientSide={true}
          label={t("trainee.mainView.filters.traineeType")}
          onChange={(e, value) => {
            handleFilterValueUpdate("traineeType", value || "", true);
          }}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="isActive"
          options={isActiveOptions}
          getOptionLabel={(option) => option && t(option.label)}
          clearOnEscape={true}
          closeIcon={false}
          value={outcomesFilterSelectedValues.isActive}
          clientSide={true}
          label={t("outcomes.mainView.filters.isActive")}
          onChange={(e, value) => {
            handleFilterValueUpdate("isActive", value ?? null, true);
          }}
        />
      </MuiFormControl>
      <ResetFilter resetFn={resetFilterValues} />
    </>
  );
};

export const OutcomesFilter = OutcomesFilterDef;
