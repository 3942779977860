import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid as MuiGrid,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  RemoveCircle as RemoveCircleIcon,
  CloudDownload as CloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@material-ui/icons";
/** Custom Components */
import { FacultyDetails } from "./FacultyDetails";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import { ShowSnackBar } from "../../Utils/ActionTrigger";
import { useSetLoading } from "../../Utils/loadingState";
import { ASConfirmation } from "../UI/ASConfirmation/ASConfirmation";
import { AutocompleteOption } from "../../Utils/Autocomplete";
/** Services */
import {
  loadFacultyList,
  saveFacultySelection,
  getFacultyExportData,
  getSubmissionsList,
  deleteParticipatingFaculty,
} from "../../services/facultyService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { useFacultyDemographicsContext } from "../../services/facultyDemographicsContext";
import { getSubmission } from "../../services/submissionService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useFacultyStyles } from "./Faculty.styles";
import { useCommonStyles } from "../shared/common.styles";

// ************************** Faculty Demographics **************************
export const Faculty = (props) => {
  const { t } = useTranslation();
  const classes = useFacultyStyles();
  const commonClasses = useCommonStyles();
  // Submission Autocomplete
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission, setSelectedSubmission] =
    useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  // Participating Faculty Autocomplete
  const [facultyList, setFacultyList] = useState([]);
  const [summary, setSummary] = useState("");
  const [hasFacultyAccess, setFacultyAccess] = useState({
    add: false,
    remove: false,
  });

  const [data, setData] = useState([]);
  const [snackbar, setSnackBar] = useState({ show: false, message: "" });

  const { alert, setAlert, clearAlert } = useAlertContext();
  //eslint-disable-next-line
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();

  const [confirmation, setConfirmation] = useState({
    type: "",
    confirm: false,
    url: "",
    name: "",
    submissionName: "",
  });

  const [reConfirmation, setReConfirmation] = useState({
    confirm: false,
  });

  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const {
    facultyPreference,
    setFacultyPreference,
    updatePreferencesForSubmission,
  } = useFacultyDemographicsContext();

  // *** On Page load, populate Submissions Dropdown Options ***
  useEffect(() => {
    getSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  /*
   * Fetch the details for selected Submission
   * and assign Add/Remove Participating access
   */
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(
    getSelectedSubmission()
  );

  useEffect(() => {
    if (selectedSubmissionId)
      getSubmission(
        selectedSubmissionId,
        setFacultyAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Faculty",
        getSelectedSubmissionName
      );
    // eslint-disable-next-line
  }, [selectedSubmissionId, setFacultyAccess]);

  // **** Submission Dropdown Value Change ****
  const handleChangeSubmission = () => {
    const selectedSubmission = getSelectedSubmission();
    setSelectedSubmission(selectedSubmission);
    setSelectedSubmissionId(selectedSubmission);
    // getSubmissionAccess();
    updatePreferencesForSubmission(selectedSubmission);
    props.handleSubmissionUpdate();
  };

  // **** Add Participating Faculty Autocomplete Value ****
  const handleChangeAutoComplete = (event) => {
    let facultyVal = event.target.value.trim();

    loadFacultyList(
      facultyVal,
      setFacultyList,
      setAlert,
      clearAlert,
      setNoOptionsText,
      t,
      selectedSubmissionId
    );
  };

  // **** Save Faculty on Selecting Value from Add Participating Faculty ****
  const handleFacultySelection = (e, value) => {
    setSnackBar({ show: false, message: "" });
    if (value) {
      saveFacultySelection(
        setSnackBar,
        setFacultyPreference,
        facultyPreference,
        value.value.universityId,
        getSelectedSubmission(),
        setAlert,
        clearAlert,
        setLoadingItems
      );
    }
    setFacultyList([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
  };

  // *** Export CSV file of Table Data ***
  const handleExport = () => {
    getFacultyExportData(
      getSelectedSubmission(),
      facultyPreference,
      setAlert,
      clearAlert,
      summary.totalCount
    );
  };

  // *** On Click of Action buttons in Confirm Dialog
  const handleDeleteAction = (action) => {
    if (action === true) {
      setReConfirmation({
        type: "",
        confirm: true,
        url: "",
        name: "",
      });
    }

    setConfirmation({
      ...confirmation,
      confirm: false,
    });
  };

  const handleConfirmDeleteAction = (action) => {
    const facultyType =
      confirmation.type === "All" ? "isRemoveTrainee" : "traineeRemove";
    if (action === true) {
      deleteParticipatingFaculty(
        confirmation.type,
        confirmation.url + `?${facultyType}=true`,
        setSnackBar,
        setFacultyPreference,
        facultyPreference,
        setAlert,
        clearAlert,
        setLoadingItems
      );
    }

    if (action === false) {
      deleteParticipatingFaculty(
        confirmation.type,
        confirmation.url + `?${facultyType}=false`,
        setSnackBar,
        setFacultyPreference,
        facultyPreference,
        setAlert,
        clearAlert,
        setLoadingItems
      );
    }
    setReConfirmation({
      type: "",
      confirm: false,
      url: "",
      name: "",
    });

    setConfirmation({
      type: "",
      confirm: false,
      url: "",
      name: "",
      submissionName: "",
    });
  };

  const getSubmissionName = () => {
    let selectedSubmissionObject = submissionList.find((submission) => {
      return (
        submission._links.self.href.split("/").pop() === selectedSubmissionId
      );
    });
    return `${selectedSubmissionObject.name}`;
  };
  // **** Function to open confirmation dialog, on click of Delete Icon/Remove All Button ****
  const handleDelete = (deleteType, url, name) => {
    if (deleteType === "All") {
      let selectedSubmissionObject = submissionList.find((submission) => {
        return (
          submission._links.self.href.split("/").pop() === selectedSubmissionId
        );
      });
      name = `${selectedSubmissionObject.name}, ${selectedSubmissionObject.year}`;
    }
    const submissionName = getSubmissionName();
    setSnackBar({ show: false, message: "" });
    setConfirmation({
      type: deleteType,
      confirm: true,
      url: url,
      name: name,
      submissionName: submissionName,
    });
  };

  const dialogConfirmLabel = "faculty.delete.confirmationBox";

  const displayText = (option) => {
    const displaytextParts = [];

    displaytextParts.push(option.value.rank);
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    return displaytextParts.join(", ");
  };

  return (
    <MuiGrid container wrap="nowrap" justify="flex-start" direction="column">
      <MuiGrid item>
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}
        <ASConfirmation
          onClose={handleDeleteAction}
          open={confirmation.confirm}
          title={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }Record.title`
          )}
          message={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }Record.body`,
            {
              name: confirmation.name,
            }
          )}
          okLabel={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }Record.okLabel`
          )}
          cancelLabel={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }Record.cancelLabel`
          )}
        />
        <ASConfirmation
          onClose={handleConfirmDeleteAction}
          open={reConfirmation.confirm}
          title={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }AssociatedRecord.title`
          )}
          message={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }AssociatedRecord.body`,
            {
              name: confirmation.name,
              submissionName: confirmation.submissionName,
            }
          )}
          okLabel={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }AssociatedRecord.okLabel`
          )}
          cancelLabel={t(
            `${dialogConfirmLabel}.delete${
              confirmation.type === "All" ? "All" : ""
            }AssociatedRecord.cancelLabel`
          )}
        />
      </MuiGrid>

      <MuiGrid item classes={{ item: commonClasses.autocompleteWrapper }}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
          setSelectedSubmissionId={setSelectedSubmissionId}
        />
      </MuiGrid>
      <MuiGrid item classes={{ item: classes.inputPanelWrapper }}>
        <MuiGrid container direction="row" justify="space-between">
          <MuiGrid item className={commonClasses.autocompleteWrapper}>
            <MuiAutocomplete
              id="participating-faculty-combo"
              value={null}
              blurOnSelect={true}
              disabled={!hasFacultyAccess.add}
              options={facultyList}
              filterOptions={(option, state) => option}
              getOptionDisabled={(option) => option.isDummy}
              disabledItemsFocusable={true}
              getOptionLabel={(option) => ""}
              getOptionSelected={(option, value) =>
                parseInt(option.value.universityId) ===
                parseInt(value.value.universityId)
              }
              noOptionsText={noOptionsText}
              onChange={(e, value) => handleFacultySelection(e, value)}
              onClose={() => {
                setFacultyList([]);
              }}
              popupIcon={<MuiSearchIcon />}
              classes={{ root: commonClasses.autocompleteRoot }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t("faculty.mainView.participatingFaculty")}
                  onChange={handleChangeAutoComplete}
                  variant="outlined"
                />
              )}
              renderOption={(option, { inputValue }) => (
                <AutocompleteOption
                  classes={classes}
                  option={option}
                  inputValue={inputValue}
                >
                  {displayText(option)}
                </AutocompleteOption>
              )}
            />
          </MuiGrid>
          <MuiGrid item classes={{ item: classes.buttonWrapper }}>
            {hasFacultyAccess.remove ? (
              <MuiButton
                variant="outlined"
                classes={{ root: classes.button }}
                disabled={!(data && data.length > 0)}
                onClick={() =>
                  handleDelete(
                    "All",
                    `/submissions/${getSelectedSubmission()}/faculty`
                  )
                }
              >
                <RemoveCircleIcon />
                &nbsp;{t("faculty.delete.deleteAllButtonText")}
              </MuiButton>
            ) : null}

            <MuiButton
              variant="outlined"
              classes={{ root: classes.button }}
              onClick={handleExport}
              title={summary.exportMessage}
              disabled={loading || summary.totalCount === 0}
            >
              <CloudDownloadIcon />
              &nbsp;{t("faculty.export.exportButtonText")}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item>
        <FacultyDetails
          handleDelete={handleDelete}
          history={props.history}
          setAlert={setAlert}
          clearAlert={clearAlert}
          loading={loading}
          setLoading={setLoadingItems}
          data={data}
          setData={setData}
          summary={summary}
          setSummary={setSummary}
        />
      </MuiGrid>
    </MuiGrid>
  );
};
