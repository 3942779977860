import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { grantType, submissionType, userRoles } from "../constants";
import { useAlertContext } from "@stanford-tds/as-components";

export const useSubmissionsAPI = () => {
  const { clearAlert, setAlert } = useAlertContext();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const loadSubmissionValues = useCallback(
    async (id, setSubmissionValues) => {
      try {
        clearAlert();
        setIsLoading(true);

        const response = await axios.get(`/submissions/${id}`);

        setSubmissionValues({
          name: response.data.name,
          year: response.data.year,
          month: response.data.month,
          admins:
            response.data.assignees?.filter(
              (assignee) => assignee.role === userRoles.submissionAdmin
            ) ?? [],
          observers:
            response.data.assignees?.filter(
              (assignee) => assignee.role === userRoles.observer
            ) ?? [],
          grant: response.data.grant ?? "",
        });
      } catch (error) {
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [clearAlert, setAlert]
  );

  const saveSubmissionValues = async (action, id, values, setFieldError) => {
    try {
      clearAlert();
      setIsSaving(true);

      const assignees = [...values.admins, ...values.observers];

      const data = {
        id,
        grantType,
        name: values.name.trim(),
        year: values.year,
        month: values.month,
        grant: values.grant?.value ?? values.grant,
        ...(assignees.length > 0 && { assignees }),
      };

      if (action === submissionType.create) {
        await axios.post("/submissions", data);
        history.push({ pathname: "/admin/submissions" });
        setAlert(
          "success",
          t("submission.create.notification.success", {
            submissionName: values.name.trim(),
          }),
          true
        );
      } else if (action === submissionType.edit) {
        await axios.put(`/submissions/${id}`, data);
        history.push({ pathname: "/admin/submissions" });
        setAlert(
          "success",
          t("submission.create.notification.update", {
            submissionName: values.name.trim(),
          }),
          true
        );
      }
    } catch (error) {
      setIsSaving(false);
      if (error.status === 409) {
        setFieldError(
          "name",
          t(
            "submission.create.form.fields.submissionName.validation.isDuplicate"
          )
        );
      } else {
        setAlert("error", error.message);
      }
    }
  };

  return { loadSubmissionValues, saveSubmissionValues, isLoading, isSaving };
};
